import {
  Divider,
  FormItem,
  PageActionHeader,
  SEO,
  Text,
} from '@myadbox/gatsby-theme-nebula'
import {
  useFeatureFlags,
  useUserSession,
} from '@myadbox/gatsby-theme-nebula/hooks'
import {useAccount} from '@myadbox/nebula-service-api'
import {useTranslation} from 'gatsby-plugin-react-i18next'

import {SettingsTabComponentName} from '../helpers'
import AccountAiTags from './AccountAiTags'
import AccountImageUploadAndPreview from './AccountImageUploadAndPreview'
import AccountUploadLogo from './AccountLogoUpload'
import AccountStorage from './AccountStorage'
import AccountTemplates from './AccountTemplates'
import AccountUsers from './AccountUsers'
import AccountWhiteLabel from './AccountWhiteLabel'
import BrandAssetUpdateForm from './BrandAssetUpdateForm'
import BulkUploadModal from './BulkUploadModal'
import ExportAssetsModal from './ExportAssetsModal'

const FormDivider = () => <Divider spanGrid color="on-dark" />

export const Account = () => {
  const {t} = useTranslation(`settings`, {
    keyPrefix: `settings.account`,
  })

  const {featureFlags} = useFeatureFlags()
  const {isSesimiAdmin} = useUserSession()

  const accountData = useAccount()
  const {account, isEnterprise} = accountData

  return (
    <>
      <SEO title={t`title`} />
      <PageActionHeader text={t`subtitle`} />

      <div
        className={`
          grid
          grid-cols-1
          gap-4
          text-sm
        `}
      >
        <FormDivider />
        <FormItem label={<FormItem.Label tag="span">{t`name`}</FormItem.Label>}>
          <Text>{account?.name}</Text>
        </FormItem>

        <FormDivider />
        <FormItem
          label={
            <FormItem.Label htmlFor="uploadImage">{t`configurations.loginBackground`}</FormItem.Label>
          }
        >
          <AccountImageUploadAndPreview />
        </FormItem>

        <FormDivider />
        <FormItem
          label={
            <FormItem.Label htmlFor="uploadLogo">{t`configurations.loginLogo`}</FormItem.Label>
          }
        >
          <AccountUploadLogo />
        </FormItem>

        {isSesimiAdmin && (
          <>
            <FormDivider />
            <FormItem
              label={
                <FormItem.Label htmlFor="aiTagsEnabled">{t`aiTags.label`}</FormItem.Label>
              }
            >
              <AccountAiTags accountData={accountData} />
            </FormItem>

            {featureFlags.whiteLabelToggleAccess && (
              <>
                <FormDivider />
                <FormItem
                  label={
                    <FormItem.Label htmlFor="isWhiteLabelled">{t`whiteLabel.label`}</FormItem.Label>
                  }
                >
                  <AccountWhiteLabel accountData={accountData} />
                </FormItem>
              </>
            )}
          </>
        )}

        {isEnterprise && (
          <>
            <FormDivider />
            <FormItem
              label={
                <FormItem.Label tag="span">{t`assetData.title`}</FormItem.Label>
              }
            >
              <div className={`flex gap-2`}>
                <ExportAssetsModal />
                <BulkUploadModal />
              </div>
            </FormItem>

            <FormDivider />
            <FormItem
              labelClassName={`self-start mt-2`}
              label={<FormItem.Label tag="span">{t`storage`}</FormItem.Label>}
            >
              <AccountStorage />
            </FormItem>

            <FormDivider />
            <FormItem
              labelClassName={`self-start mt-2`}
              label={<FormItem.Label tag="span">{t`users`}</FormItem.Label>}
            >
              <AccountUsers />
            </FormItem>

            <FormDivider />
            <FormItem
              labelClassName={`self-start mt-2`}
              label={
                <FormItem.Label tag="span">{t`templateVariables`}</FormItem.Label>
              }
            >
              {featureFlags.showUsage && <AccountTemplates />}
            </FormItem>
          </>
        )}

        <FormDivider />
        <BrandAssetUpdateForm accountData={accountData} />
      </div>
    </>
  )
}

Account.displayName = `Account` satisfies SettingsTabComponentName
export default Account
