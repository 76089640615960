import qs from 'qs'
import {CognitoAuthToken} from '../../hooks/useUserSession/types'

const TITAN_PORT = process.env.TITAN_PORT || 3099

const getLanguageLocale = (language: string): string => language.split(`-`)[0]

export function isLocalhost(hostname: string): boolean {
  const LOCAL_DOMAINS = [`localhost`, `127.0.0.1`]
  return LOCAL_DOMAINS.includes(hostname)
}

export const getCoreAppUrl = (
  domain: string,
  product: string,
  token: CognitoAuthToken,
  lang = `en`
): string => {
  if (!domain) return `/`
  if (!token) return `${domain}/${product}`

  const params = buildParams(token, product, lang)
  return new URL(`${domain}/nebula_login?${params}`).href
}

export const getTitanAppUrl = (
  protocol = `https:`,
  hostname = `localhost`,
  jwt: string,
  locale: string,
  returnToUrl = ``
): string => {
  const params: {
    accessToken: string
    lng: string
    returnToUrl?: string
  } = {
    accessToken: jwt,
    lng: locale,
  }

  if (returnToUrl) params.returnToUrl = returnToUrl

  const searchParams = new URLSearchParams(params).toString()

  if (isLocalhost(hostname))
    return new URL(
      `${protocol}//${hostname}:${TITAN_PORT}/validate?${searchParams}`
    ).href

  const accountName = hostname.split(`.`)[0]
  const primaryDomain = hostname.split(`.`).slice(-2).join(`.`)

  return new URL(
    `${protocol}//${accountName}.compliance.${primaryDomain}/validate?${searchParams}`
  ).href
}

export const buildParams = (
  token: CognitoAuthToken,
  product: string,
  language: string
): string => {
  if (!token) return ``

  const lang = getLanguageLocale(language)

  return qs.stringify(
    {
      access_token: token.accessToken?.jwtToken,
      refresh_token: token.refreshToken?.token,
      exp: token.accessToken?.payload?.exp,
      redirect_url: `/${product}`,
      lang,
    },
    {encode: false}
  )
}
