import {useContext, useDebugValue} from 'react'
import {UserContext} from './UserProvider'

export const useProfile = () => {
  const profile = useContext(UserContext)

  if (!profile) {
    throw new Error(`useProfile must be used within a UserProvider`)
  }

  useDebugValue(`profile: ${profile}`)

  return profile
}

export default useProfile
