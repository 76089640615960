import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  CloudinaryUploadWidgetProps,
  CloudinaryWidgetLib,
  UploadHandler,
  WidgetOptions,
} from '../types'
import useWidgetStyles from './hooks/useWidgetStyles'
import {cloudinaryText} from './locales'

export interface Props {
  cloudinary?: CloudinaryWidgetLib | null
  onUploaded: UploadHandler
  widgetOptions?: Partial<WidgetOptions>
  children: React.ReactNode | React.ReactNode[]
}

/**
 * NOTE:
 * Please make sure to match this value in the value of
 * `limit_reached` key of `locales/en-AU/cloudinary.json` file
 */
const MAX_FILES = 100

export const UploadWidgetContext =
  createContext<CloudinaryUploadWidgetProps | null>(null)
UploadWidgetContext.displayName = `UploadWidgetContext Context`

const defaultWidgetOptions = {
  multiple: false,
  maxFiles: MAX_FILES,
  showPoweredBy: false,
}

export const cloudinaryIframeSelector = `iframe[src*="widget.cloudinary.com"]`

const cleanUp = (): void => {
  const iframe = document.querySelector(cloudinaryIframeSelector)
  iframe && iframe?.parentNode?.removeChild(iframe)
}

const cloudinaryWidgetLib =
  typeof window !== `undefined`
    ? //@ts-ignore cloudinary is a custom function
      (window?.cloudinary as unknown as CloudinaryWidgetLib) // avoid TS confusion with cloudinary-core's global definitions
    : null

const UploadWidgetProvider = ({
  children,
  cloudinary = cloudinaryWidgetLib,
  widgetOptions = {},
  onUploaded,
}: Props) => {
  const widgetStyles = useWidgetStyles()
  const [uploadWidget, setUploadWidget] =
    useState<CloudinaryUploadWidgetProps | null>(null)

  const widgetCreationOptions = useMemo(() => {
    return {
      ...defaultWidgetOptions,
      ...widgetOptions,
      ...widgetStyles,
      language: widgetOptions?.language ?? `en-AU`,
      text: cloudinaryText,
    } as WidgetOptions
  }, [widgetStyles, widgetOptions])

  useEffect(() => {
    if (!cloudinary) {
      // eslint-disable-next-line no-console
      console.error(`Cloudinary is not loaded`)
      return
    }

    setUploadWidget(
      cloudinary.createUploadWidget(widgetCreationOptions, onUploaded)
    )

    return () => cleanUp()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloudinary])

  useEffect(() => {
    uploadWidget?.update(widgetStyles)
  }, [widgetStyles, uploadWidget])

  return (
    <UploadWidgetContext.Provider value={uploadWidget}>
      {children}
    </UploadWidgetContext.Provider>
  )
}

export const useUploadWidget = (): CloudinaryUploadWidgetProps | null => {
  const uploadWidget = useContext(UploadWidgetContext)
  if (!uploadWidget) return null

  return uploadWidget
}

export default UploadWidgetProvider
