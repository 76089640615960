import mixpanel from 'mixpanel-browser'

let currentUserId: string | null = null
let currentCompanyName: string | null = null
let mixpanelTokenSet: boolean | null = false

export const initializeMixpanel = (token: string, mixPanelDebug: boolean) => {
  if (token) {
    mixpanelTokenSet = true
    mixpanel.init(token, {
      debug: mixPanelDebug,
      ignore_dnt: true,
    })
  } else {
    console.error(`Mixpanel token is missing!`)
  }
}

export const setUserSession = (
  userId: string,
  companyName: string,
  properties: Record<string, unknown> = {}
) => {
  if (mixpanelTokenSet) {
    currentUserId = userId
    currentCompanyName = companyName
    mixpanel.people.set(userId, properties)
  }
}

export const getCurrentUserData = (): {
  userId: string | null
  companyName: string | null
  mixpanelTokenSet: boolean | null
} => {
  return {
    userId: currentUserId,
    companyName: currentCompanyName,
    mixpanelTokenSet: mixpanelTokenSet,
  }
}

export const initUserSession = (userId: string) => {
  const companyName = location.hostname?.split(`.`)[0]
  setUserSession(userId, companyName)
}
