// import useDarkMode from 'use-dark-mode'
// import {useMemo} from 'react'

// const lightStyles = {
//   palette: {
//     window: `#FCFCFD`,
//     windowBorder: `#A0AEC0`,
//     tabIcon: `#A4D21F`,
//     menuIcons: `#576475`,
//     textDark: `#2F3642`,
//     textLight: `#FCFCFD`,
//     link: `#BCEB35`,
//     action: `#BCEB35`,
//     inactiveTabIcon: `#576475`,
//     error: `#F56565`,
//     inProgress: `#BCEB35`,
//     complete: `#48BB78`,
//     sourceBg: `#DAE3EC`,
//   },
//   fonts: {
//     'Inter, Roboto, sans-serif': `https://fonts.googleapis.com/css2?family=Inter&display=swap`,
//   },
// }

const darkStyles = {
  palette: {
    window: `#1A212D`,
    windowBorder: `#576475`,
    tabIcon: `#FCFCFD`,
    menuIcons: `#96AAC3`,
    textDark: `#2F3642`,
    textLight: `#FCFCFD`,
    link: `#DAFF54`,
    action: `#DAFF54`,
    inactiveTabIcon: `#A0AEC0`,
    error: `#F56565`,
    inProgress: `#DAFF54`,
    complete: `#48BB78`,
    sourceBg: `#121721`,
  },
  fonts: {
    'Inter, Roboto, sans-serif': `https://fonts.googleapis.com/css2?family=Inter&display=swap`,
  },
}

interface WidgetStyles {
  styles: Record<string, string | object>
}

export const useWidgetStyles = (): WidgetStyles => {
  // const {value: isDark} = useDarkMode(false)
  // const widgetStyles = useMemo(
  //   () => ({styles: isDark ? darkStyles : lightStyles}),
  //   [isDark]
  // )
  return {styles: darkStyles}
}

export default useWidgetStyles
