import {useQuery} from '@apollo/client'
import {ASSET_TAGS} from '../../queries'
import {AssetTags} from '../../types'

export const useAssetTags = () => {
  const {data, loading: isLoadingTags} = useQuery<AssetTags>(ASSET_TAGS)

  return {
    assetTags: data?.assetTags,
    isLoadingTags,
  }
}
