import {type TextAreaProps} from './TextArea'

export const textAreaClassName = `
  focus:shadow-outline
  focus:outline-none
  rounded-400
  border
  block
  form-textarea
  duration-150
  ease-in-out
  transition-default
  w-full
`

export const enabledClassName = `
  focus:bg-white
  hover:bg-white
  hocus:text-ui-1000
  bg-ui-50
  text-ui-800
  dark:hocus:bg-ui-200
  dark:bg-ui-200
  dark:border-ui-200
`

export const disabledClassName = `
  bg-ui-200
  border-ui-200
  text-ui-500
  dark:text-ui-300
  dark:bg-ui-50
  dark:border-ui-50
  cursor-not-allowed
`

export const sizesClassName = {
  md: `
    sm:text-sm
    sm:leading-5
    px-3
    py-2
  `,
  sm: `
    sm:text-xs
    sm:leading-5
    px-2
    py-1
  `,
}

export const getTextAreaClassName = (props: Partial<TextAreaProps>) => {
  return `
    ${textAreaClassName}
    ${sizesClassName[props.size ?? `sm`]}
    ${props.className || ``}
    ${props.readOnly ? disabledClassName : enabledClassName}
    ${props.noResize ? `resize-none` : `resize-y`}
  `
}
