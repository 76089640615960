/* eslint-disable max-lines */
import {useLazyQuery, useMutation} from '@apollo/client'
import {useCallback} from 'react'
import {RequestOption} from '../../../types'
import {
  ADD_DATASET,
  ADD_SCHEMA,
  ADD_SCHEMA_FIELD,
  DELETE_DATASET,
  DELETE_SCHEMA,
  UPDATE_DATASET,
  UPDATE_SCHEMA,
  UPSERT_DATASETS,
  UPSERT_SCHEMA_FIELDS,
} from '../../mutations'
import {
  FETCH_ALL_DATASETS_BY_SCHEMA,
  FETCH_DATASETS,
  FETCH_DATASETS_BY_SCHEMA,
  FETCH_DATASETS_BY_SCHEMA_NAMES,
  FETCH_DATASET_USAGE,
  FETCH_NESTEDSCHEMA,
  FETCH_NESTEDSCHEMAS,
  FETCH_PARENT_SCHEMA_BY_FIELDS,
  FETCH_SCHEMA_IDS_BY_FIELD,
  FETCH_SCHEMA_USAGE,
  FETCH_SCHEMA_WITH_DATASETS,
} from '../../queries'
import {
  AddSchemaFieldMutator,
  AddSchemaMutator,
  UpdateSchemaMutator,
  UpsertSchemaFieldsMutator,
  UseDatasetsOutput,
} from '../../types'
import {addSchemaUpdater} from '../../updaters'

export const useDatasets = (): UseDatasetsOutput => {
  const [lazyFetchDatasets, fetchDatasetsResult] = useLazyQuery(FETCH_DATASETS)
  const fetchDatasets = useCallback(
    (ids: string[]) => lazyFetchDatasets({variables: {ids}}),
    [lazyFetchDatasets]
  )

  const [lazyFetchSchemas, fetchSchemasResult] =
    useLazyQuery(FETCH_NESTEDSCHEMAS)
  const fetchSchemas = useCallback(
    (isParent: boolean, ids?: string[]) =>
      lazyFetchSchemas({variables: {isParent, ids}}),
    [lazyFetchSchemas]
  )

  const [lazyFetchSchema, fetchSchemaResult] = useLazyQuery(FETCH_NESTEDSCHEMA)
  const fetchSchema = useCallback(
    (id: string) => lazyFetchSchema({variables: {id}}),
    [lazyFetchSchema]
  )

  const [lazyFetchSchemaWithDatasets, fetchSchemaWithDatasetsResult] =
    useLazyQuery(FETCH_SCHEMA_WITH_DATASETS)
  const fetchSchemaWithDatasets = useCallback(
    (id: string) => lazyFetchSchemaWithDatasets({variables: {id}}),
    [lazyFetchSchemaWithDatasets]
  )

  const [lazyFetchDatasetsBySchema, fetchDatasetsBySchemaResult] = useLazyQuery(
    FETCH_DATASETS_BY_SCHEMA,
    {
      fetchPolicy: `cache-and-network`,
    }
  )

  const fetchDatasetsBySchema = useCallback(
    (schemaId: string, limit: number, offset: number, keyword: string) => {
      return lazyFetchDatasetsBySchema({
        variables: {
          id: schemaId,
          option: {limit, offset, keyword},
        },
      })
    },
    [lazyFetchDatasetsBySchema]
  )

  const [lazyFetchDatasetsUsage, fetchDatasetsUsageResult] =
    useLazyQuery(FETCH_DATASET_USAGE)
  const fetchDatasetsUsage = useCallback(
    (ids: string[]) => {
      return lazyFetchDatasetsUsage({variables: {ids}})
    },
    [lazyFetchDatasetsUsage]
  )

  const [lazyFetchDatasetsBySchemaName] = useLazyQuery(
    FETCH_DATASETS_BY_SCHEMA_NAMES
  )
  const fetchDatasetsBySchemaName = useCallback(
    (name: string, options?: RequestOption, filters?) =>
      lazyFetchDatasetsBySchemaName({
        variables: {name, options, filters},
      }),
    [lazyFetchDatasetsBySchemaName]
  )

  const [lazyFetchSchemaUsage, fetchSchemaUsageResult] =
    useLazyQuery(FETCH_SCHEMA_USAGE)
  const fetchSchemaUsage = useCallback(
    (id: string) => lazyFetchSchemaUsage({variables: {id}}),
    [lazyFetchSchemaUsage]
  )

  const [lazyFetchDatasetsByFields, fetchDatasetsByFieldsResult] = useLazyQuery(
    FETCH_SCHEMA_IDS_BY_FIELD
  )

  const fetchDatasetsByFields = useCallback(
    (field: string, value: string) =>
      lazyFetchDatasetsByFields({variables: {field, value}}),
    [lazyFetchDatasetsByFields]
  )

  const [lazyFetchSchemaByFields, fetchSchemasWithDatasetsByFieldsResult] =
    useLazyQuery(FETCH_PARENT_SCHEMA_BY_FIELDS)
  const fetchSchemasWithDatasetsByFields = useCallback(
    (fields: string[]) => lazyFetchSchemaByFields({variables: {fields}}),
    [lazyFetchSchemaByFields]
  )

  const [lazyFetchAllDatasetsBySchema, fetchAllDatasetsBySchemaResult] =
    useLazyQuery(FETCH_ALL_DATASETS_BY_SCHEMA)
  const fetchAllDatasetsBySchema = useCallback(
    (id: string) => lazyFetchAllDatasetsBySchema({variables: {id}}),
    [lazyFetchAllDatasetsBySchema]
  )

  const [doAddSchema, addSchemaResult] = useMutation(ADD_SCHEMA)
  const addSchema: AddSchemaMutator = useCallback(
    values => {
      return doAddSchema({
        variables: {input: values},
        update: addSchemaUpdater(),
      })
    },
    [doAddSchema]
  )

  const [doUpdateSchema, updateSchemaResult] = useMutation(UPDATE_SCHEMA)
  const updateSchema: UpdateSchemaMutator = useCallback(
    (id, values) => {
      return doUpdateSchema({variables: {id, input: values}})
    },
    [doUpdateSchema]
  )

  const [doAddSchemaField, addSchemaFieldResult] = useMutation(ADD_SCHEMA_FIELD)
  const addSchemaField: AddSchemaFieldMutator = useCallback(
    (schemaId, values) => {
      return doAddSchemaField({
        variables: {schemaId, input: values},
        refetchQueries: [
          {
            query: FETCH_NESTEDSCHEMA,
            variables: {id: schemaId},
          },
        ],
      })
    },
    [doAddSchemaField]
  )

  const [doUpsertSchemaFields, upsertSchemaFieldsResult] =
    useMutation(UPSERT_SCHEMA_FIELDS)
  const upsertSchemaFields: UpsertSchemaFieldsMutator = useCallback(
    (schemaId, fields) => {
      return doUpsertSchemaFields({
        variables: {schemaId, inputs: fields},
        refetchQueries: [
          {
            query: FETCH_NESTEDSCHEMA,
            variables: {id: schemaId},
          },
        ],
      })
    },
    [doUpsertSchemaFields]
  )

  const [doDeleteSchema, deleteSchemaResult] = useMutation(DELETE_SCHEMA, {
    refetchQueries: [
      {
        query: FETCH_NESTEDSCHEMAS,
        variables: {isParent: true},
      },
    ],
    onError: () => null,
  })
  const deleteSchema = useCallback(
    id => {
      return doDeleteSchema({variables: {id}})
    },
    [doDeleteSchema]
  )

  const [doUpsertDatasets, upsertDatasetsResponse] = useMutation(
    UPSERT_DATASETS,
    {
      refetchQueries: [
        {
          query: FETCH_NESTEDSCHEMAS,
          variables: {isParent: true},
        },
      ],
    }
  )
  const upsertDatasets = useCallback(
    (schemaId, data) => {
      return doUpsertDatasets({
        variables: {schemaId, data},
      })
    },
    [doUpsertDatasets]
  )

  const [doAddDataset, addDatasetResult] = useMutation(ADD_DATASET)
  const addDataset = useCallback(
    (schemaId, data) => {
      return doAddDataset({
        variables: {input: {data, nestedSchema: schemaId}},
      })
    },
    [doAddDataset]
  )

  const [doUpdateDataset, updateDatasetResult] = useMutation(UPDATE_DATASET)
  const updateDataset = useCallback(
    (id, data) => {
      return doUpdateDataset({
        variables: {id, data},
      })
    },
    [doUpdateDataset]
  )

  const [doDeleteDataset, deleteDatasetResult] = useMutation(DELETE_DATASET)
  const deleteDataset = useCallback(
    id => {
      return doDeleteDataset({
        variables: {id},
      })
    },
    [doDeleteDataset]
  )

  return {
    addDataset,
    addDatasetResult,
    addSchema,
    addSchemaField,
    addSchemaFieldResult,
    addSchemaResult,
    deleteDataset,
    deleteDatasetResult,
    deleteSchema,
    deleteSchemaResult,
    fetchAllDatasetsBySchema,
    fetchAllDatasetsBySchemaResult,
    fetchDatasets,
    fetchDatasetsByFields,
    fetchDatasetsByFieldsResult,
    fetchDatasetsBySchema,
    fetchDatasetsBySchemaName,
    fetchDatasetsBySchemaResult,
    fetchDatasetsResult,
    fetchDatasetsUsage,
    fetchDatasetsUsageResult,
    fetchSchema,
    fetchSchemaResult,
    fetchSchemas,
    fetchSchemasResult,
    fetchSchemasWithDatasetsByFields,
    fetchSchemasWithDatasetsByFieldsResult,
    fetchSchemaUsage,
    fetchSchemaUsageResult,
    fetchSchemaWithDatasets,
    fetchSchemaWithDatasetsResult,
    updateDataset,
    updateDatasetResult,
    updateSchema,
    updateSchemaResult,
    upsertDatasets,
    upsertDatasetsResponse,
    upsertSchemaFields,
    upsertSchemaFieldsResult,
  }
}
