// translations purely for cloudinary widget
import enAuTrans from './en-AU.json'
import enUsTrans from './en-US.json'
import frCaTrans from './fr-CA.json'

export const cloudinaryText = {
  'en-AU': enAuTrans,
  'en-US': enUsTrans,
  'fr-CA': frCaTrans,
}
