module.exports = [{
      plugin: require('../../../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":false,"preconnect":["https://rsms.me"],"web":[{"name":"Inter var","file":"https://rsms.me/inter/inter.css"}]},
    },{
      plugin: require('../../../packages/plugin/gatsby-theme-nebula/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyPluginReactI18next":{"resolve":"gatsby-plugin-react-i18next","options":{"languages":["en-AU","en-US","fr-CA","id-ID","ko-KR","pt-BR"],"defaultLanguage":"en-AU","localeJsonSourceName":"locale","i18nextOptions":{"debug":false,"nsSeparator":false,"useSuspense":false,"fallbackLng":"en-AU","interpolation":{"escapeValue":false}}}},"disablePurgeCss":false,"activeEnv":"production","cloudName":"mabx-eu-prod","region":"eu-central-1","adminPoolId":"eu-central-1_tN7ITl9gi","adminPoolAppId":"5ig4b2leo9qt3ct85gmu708it2","loginBackgroundFileUrl":"https://res.cloudinary.com/mabx-eu-dev/image/upload/v1/demo-au/bgd-myadbox-3_ayck83","loginBackgroundPosition":"center","loginBackgroundOverlay":6,"primaryColor":"#544AE5"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en-AU","en-US","fr-CA","id-ID","ko-KR","pt-BR"],"defaultLanguage":"en-AU","localeJsonSourceName":"locale","i18nextOptions":{"debug":false,"nsSeparator":false,"useSuspense":false,"fallbackLng":"en-AU","interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../../../packages/gatsby/gatsby-theme-arkaroola/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyPluginReactI18next":{"resolve":"gatsby-plugin-react-i18next","options":{"languages":["en-AU","en-US","fr-CA","id-ID","ko-KR","pt-BR"],"defaultLanguage":"en-AU","localeJsonSourceName":"locale","i18nextOptions":{"debug":false,"nsSeparator":false,"useSuspense":false,"fallbackLng":"en-AU","interpolation":{"escapeValue":false}}}},"disablePurgeCss":false,"activeEnv":"production","cloudName":"mabx-eu-prod","region":"eu-central-1","adminPoolId":"eu-central-1_tN7ITl9gi","adminPoolAppId":"5ig4b2leo9qt3ct85gmu708it2","loginBackgroundFileUrl":"https://res.cloudinary.com/mabx-eu-dev/image/upload/v1/demo-au/bgd-myadbox-3_ayck83","loginBackgroundPosition":"center","loginBackgroundOverlay":6,"primaryColor":"#544AE5"},
    },{
      plugin: require('../../../packages/gatsby/gatsby-theme-chevron/gatsby-browser.tsx'),
      options: {"plugins":[],"gatsbyPluginReactI18next":{"resolve":"gatsby-plugin-react-i18next","options":{"languages":["en-AU","en-US","fr-CA","id-ID","ko-KR","pt-BR"],"defaultLanguage":"en-AU","localeJsonSourceName":"locale","i18nextOptions":{"debug":false,"nsSeparator":false,"useSuspense":false,"fallbackLng":"en-AU","interpolation":{"escapeValue":false}}}},"disablePurgeCss":false,"activeEnv":"production","cloudName":"mabx-eu-prod","region":"eu-central-1","adminPoolId":"eu-central-1_tN7ITl9gi","adminPoolAppId":"5ig4b2leo9qt3ct85gmu708it2","loginBackgroundFileUrl":"https://res.cloudinary.com/mabx-eu-dev/image/upload/v1/demo-au/bgd-myadbox-3_ayck83","loginBackgroundPosition":"center","loginBackgroundOverlay":6,"primaryColor":"#544AE5"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sesimi","short_name":"Sesimi","start_url":"/","background_color":"#03292D","theme_color":"#232934","display":"minimal-ui","icon":"src/images/favicon-500.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2b227c10d1014148bc35c9b85a6f5b0a"},
    },{
      plugin: require('../../../packages/plugin/gatsby-plugin-intercom-spa-mabx/gatsby-browser.js'),
      options: {"plugins":[],"include_in_development":false,"app_id":"fxsvl5a1","delay_timeout":100},
    },{
      plugin: require('../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://5534dbe3ccea4e2bbc1122600955891c@o262538.ingest.sentry.io/5261046","environment":"production"},
    },{
      plugin: require('../../../packages/plugin/gatsby-plugin-launchdarkly/gatsby-browser.js'),
      options: {"plugins":[],"clientSideID":"6167c6dde5500125ad84459c","options":{"streaming":true}},
    },{
      plugin: require('../../../packages/plugin/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyPluginReactI18next":{"resolve":"gatsby-plugin-react-i18next","options":{"languages":["en-AU","en-US","fr-CA","id-ID","ko-KR","pt-BR"],"defaultLanguage":"en-AU","localeJsonSourceName":"locale","i18nextOptions":{"debug":false,"nsSeparator":false,"useSuspense":false,"fallbackLng":"en-AU","interpolation":{"escapeValue":false}}}},"disablePurgeCss":false,"activeEnv":"production","cloudName":"mabx-eu-prod","region":"eu-central-1","adminPoolId":"eu-central-1_tN7ITl9gi","adminPoolAppId":"5ig4b2leo9qt3ct85gmu708it2","loginBackgroundFileUrl":"https://res.cloudinary.com/mabx-eu-dev/image/upload/v1/demo-au/bgd-myadbox-3_ayck83","loginBackgroundPosition":"center","loginBackgroundOverlay":6,"primaryColor":"#544AE5"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
