import {FC, ReactElement} from 'react'
import Text from '../Text'
import ScrollGradient from './ScrollGradient/ScrollGradient'

interface Props {
  stageContent?: ReactElement
  nav?: ReactElement
  navMobile?: ReactElement
  children?: React.ReactNode
}

const tabbedMenuShim = `
  md:after:block
  md:after:h-14
`

export const regularLayoutStyle = {
  '--grid-height': `calc(var(--viewport-height) - 4.5rem)`,
  '--grid-template-rows-var-1': `max(10rem, var(--viewport-height) - 14rem) 1fr`,

  '--grid-template-columns-var-1': `1fr min(45%, 40rem)`,
} as React.CSSProperties

const Workspace: FC<Props> = ({
  children,
  nav,
  navMobile,
  stageContent = (
    <Text variant="headingLarge" color="textSecondary">
      Preview unavailable
    </Text>
  ),
}) => {
  return (
    <>
      <div
        className={`
          until-lg:grid-rows-[--grid-template-rows-var-1]
          until-lg:grid-cols-1
          grid
          h-full
          max-h-[--grid-height]
          lg:grid-cols-[--grid-template-columns-var-1]
          lg:overflow-hidden
        `}
        style={regularLayoutStyle}
      >
        <div
          className={`
            relative
            h-full
            min-h-0
            min-w-0
          `}
        >
          <ScrollGradient opacity={0.6} />
          {stageContent}
          <ScrollGradient opacity={0.6} bottom={true} />
        </div>

        <div
          className={`
            relative
            z-10
            min-h-0
            shadow-md
          `}
        >
          <div
            id="sidebar"
            className={`
              lg:pl-18
              bg-ui-200
              dark:bg-ui-50
              h-full
              scroll-smooth
              lg:overflow-y-auto
            `}
          >
            {navMobile && nav}
            <div
              className={`
                ${tabbedMenuShim}
                bg-ui-100
                grid
                min-h-full
                flex-1
                grid-cols-[1rem_1fr_1rem]
                content-start
                gap-y-8
                pb-12
                pt-8
                lg:grid-cols-[2rem_1fr_2rem]
                xl:pt-12
                [&>*:not([class~="col-span-full"])]:col-start-2
              `}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
      {nav && navMobile}
    </>
  )
}

export default Workspace
