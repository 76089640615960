/* eslint-disable max-lines */
import {
  AssetType,
  colorModes,
  Format,
  MultiPageConfig,
  MultiPageValues,
  Quality,
  resolutions,
  Unit,
} from '@myadbox/nebula-template-utils'
import {accessLevels, TeamAccess, UserAccess} from './src/access/types'
import {
  Approval,
  ApprovalStatus,
  Approver,
  ApproverType,
  RequesterType,
} from './src/approvals/types'
import {Category} from './src/categories/types'
import {Annotation, Comment, CommentCount} from './src/comments/types'
import {Dataset, NestedSchema} from './src/datasets/types'
import {User} from './src/profiles/types'
import {Team} from './src/teams/types'
import {Template, TemplateType} from './src/templates/types'

import {
  Asset,
  cloudinaryFormats,
  cloudinaryResourceTypes,
} from './src/assets/types'

/**
 * Reusable mock data
 * TODO:
 * Create builders for each model instead
 */

export {
  mockAccount,
  mockBilling,
  mockIntegration,
} from './src/accounts/fixtures'

export const mockUser: User = {
  id: `123`,
  userId: `user1`,
  givenName: `John`,
  familyName: `Doe`,
  fullName: `John Doe`,
  email: `jdoe@example.org`,
  avatar: undefined,
  language: `fr-CA`,
  timezone: `Australia/Melbourne`,
  sso: false,
  isAdmin: false,
  darkMode: `AUTO`,
  status: `ENABLED`,
  notificationPreferences: {
    templateShared: false,
  },
}

export const mockTeam: Team = {
  id: `id`,
  name: `team name`,
  userIds: [`user1`, `user2`],
  avatar: `an avatar`,
}

export const mockContent1 = {
  preset: `Square`,
  width: 600,
  height: 600,
  unit: `px`,
  fields: {
    global: {
      lang: `en`,
    },
    '001': {
      firstName: `[Name]`,
    },
  },
  outputSettings: {
    format: Format.PDF,
    quality: Quality.Optimised,
    unit: Unit.PX,
    resolutions: resolutions.x1,
    bleed: 15,
    slug: 48,
    width: 600,
    height: 600,
    label: `Square`,
    cdnImages: false,
    colorMode: colorModes.RGB,
  },
  templateUrl: `http://path/to/file`,
  secureUrl: `http://path/to/file`,
}

export const mockContent2 = {
  preset: `Portrait`,
  width: 1080,
  height: 1920,
  unit: `px`,
  fields: {
    global: {
      lang: `en`,
    },
    '001': {
      firstName: `[Name]`,
    },
  },
  outputSettings: {
    format: Format.PDF,
    quality: Quality.Optimised,
    unit: Unit.PX,
    resolutions: resolutions.x1,
    bleed: 15,
    slug: 48,
    width: 1080,
    height: 1920,
    label: `Portrait`,
    cdnImages: false,
    colorMode: colorModes.RGB,
  },
  templateUrl: `http://path/to/file`,
  secureUrl: `http://path/to/file`,
}

export const mockAsset: Asset = {
  id: `asset1`,
  type: AssetType.Image,
  derivedFrom: {
    id: `asset0`,
    name: `Asset zero`,
  },
  name: `Mock asset`,
  description: `Description of mock asset`,
  tags: [`tag1`, `tag2`],
  categories: [
    {
      id: `cat1`,
      title: `Category 1`,
      parent: ``,
    },
    {
      id: `cat2`,
      title: `Category 2`,
      parent: `cat1`,
    },
    {
      id: `cat3`,
      title: `Category 3`,
      parent: ``,
    },
  ],
  datasets: [],
  accesses: [],
  versions: [
    {
      active: false,
      cloudinaryId: `123`,
      content: mockContent1,
      thumbnailId: `456`,
      previewId: `789`,
      originalFilename: `somefile`,
      originalFormat: cloudinaryFormats.PNG,
      resourceType: cloudinaryResourceTypes.Image,
      bytes: 1,
      width: 1,
      height: 1,
      addedById: `user1`,
      pages: undefined,
      createdAt: ``,
    },
    {
      active: true,
      cloudinaryId: `abc`,
      content: mockContent2,
      thumbnailId: `def`,
      previewId: `ghi`,
      originalFilename: `somefile`,
      originalFormat: cloudinaryFormats.PNG,
      resourceType: cloudinaryResourceTypes.Image,
      bytes: 1,
      width: 1,
      height: 1,
      addedById: `user2`,
      pages: undefined,
      createdAt: ``,
    },
  ],
  linkages: [
    {
      type: `GOOGLE_ADS`,
      apps: [],
    },
  ],
  stats: {views: 0, downloads: 0, events: []},
  approvers: undefined,
  createdBy: {
    userId: `user1`,
    fullName: `John Doe`,
  },
  createdAt: `2021-01-01 00:00:00`,
}

export const mockVideoAsset: Asset = {
  id: `asset1`,
  type: AssetType.Video,
  derivedFrom: {
    id: `asset0`,
    name: `Asset zero`,
  },
  name: `Mock asset`,
  description: `Description of mock asset`,
  tags: [`tag1`, `tag2`],
  categories: [
    {
      id: `cat1`,
      title: `Category 1`,
      parent: ``,
    },
    {
      id: `cat2`,
      title: `Category 2`,
      parent: `cat1`,
    },
    {
      id: `cat3`,
      title: `Category 3`,
      parent: ``,
    },
  ],
  datasets: [],
  accesses: [],
  versions: [
    {
      cloudinaryId: `volvo/bbof9rb0xz6pa9x5imbu.zip`,
      content: mockContent1,
      previewId: `volvo/gfripovcmqk2dugrt8fm`,
      thumbnailId: `volvo/gfripovcmqk2dugrt8fm`,
      originalFilename: `2774-TOYOTACA-time-to-toyota-PRINT-tier-3`,
      originalFormat: cloudinaryFormats.Unknown,
      resourceType: cloudinaryResourceTypes.Raw,
      bytes: 891220,
      addedById: `user1`,
      createdAt: `2021-06-03T05:23:57.576Z`,
      exportSizes: [{format: `mp4`, quality: `low`, size: 0}],
    },
  ],
  stats: {views: 0, downloads: 0, events: []},
  approvers: undefined,
  createdBy: {
    userId: `user1`,
    fullName: `John Doe`,
  },
  createdAt: `2021-01-01 00:00:00`,
}

export const mockTemplateAsset: Asset = {
  id: `123`,
  type: AssetType.Template,
  derivedFrom: {
    id: `asset0`,
    name: `My Template`,
  },
  name: `New print presets`,
  description: ``,
  tags: [],
  categories: [],
  datasets: [],
  accesses: [],
  stats: {views: 13, downloads: 0, events: []},
  versions: [
    {
      cloudinaryId: `volvo/bbof9rb0xz6pa9x5imbu.zip`,
      previewId: `volvo/gfripovcmqk2dugrt8fm`,
      thumbnailId: `volvo/gfripovcmqk2dugrt8fm`,
      originalFilename: `2774-TOYOTACA-time-to-toyota-PRINT-tier-3`,
      originalFormat: cloudinaryFormats.Unknown,
      resourceType: cloudinaryResourceTypes.Raw,
      bytes: 891220,
      addedById: `user1`,
      createdAt: `2021-06-03T05:23:57.576Z`,
    },
    {
      cloudinaryId: `volvo/depz4izjk1rgqvljkdcl.zip`,
      previewId: `volvo/bnb8haubsudwxnzhavqy`,
      thumbnailId: `volvo/bnb8haubsudwxnzhavqy`,
      originalFilename: `2774-TOYOTACA-time-to-toyota-PRINT-tier-3`,
      originalFormat: cloudinaryFormats.Unknown,
      resourceType: cloudinaryResourceTypes.Raw,
      bytes: 891274,
      addedById: `user2`,
      createdAt: `2021-06-03T05:32:50.748Z`,
    },
  ],
  approvers: [
    {
      approverId: `bob`,
      approverType: ApproverType.USER,
    },
    {approverId: `456`, approverType: ApproverType.TEAM},
  ],
  createdBy: {
    userId: `user1`,
    fullName: `John Doe`,
  },
  createdAt: `2021-06-03T05:23:57.577Z`,
}

export const mockDataset: Dataset = {
  id: `1`,
  data: {somekey1: `data here`},
  nestedSchema: {
    id: `11`,
    title: `Some nested title`,
    name: `somename`,
    descendants: [
      {
        id: `111`,
        title: `Some nested descendent title`,
        name: `somekey1`,
        type: `string`,
      },
    ],
  },
}

export const mockTemplate: Template = {
  id: `123`,
  type: TemplateType.Html,
  publicId: `account/templates/date/index.js`,
  secureUrl: `http://path/to/file`,
  thumbnailPublicId: `234`,
  templateUrl: `http://path/to/file`,
  hostedUrl: `http://path/to/file`,
  configValues: {
    formats: [Format.PDF],
    defaultSize: `Full page`,
    sizes: [
      {
        label: `Full page`,
        width: 990,
        height: 1361,
        format: Format.PDF,
        quality: Quality.Optimised,
        unit: Unit.PX,
      },
      {
        label: `Half page`,
        width: 990,
        height: 661,
        format: Format.PDF,
        quality: Quality.Optimised,
        unit: Unit.PX,
      },
    ],
    fields: [
      {
        name: `fieldA`,
        type: `text`,
        inputMode: `text`,
        required: true,
        maxLength: undefined,
        minLength: undefined,
        pattern: undefined,
        options: {
          label: `Field A`,
        },
      },
    ],
    features: [],
  },
  defaultValues: {
    fieldA: `default value`,
  },
}

export const mockMultiPageTemplate: Template<MultiPageConfig, MultiPageValues> =
  {
    id: `123`,
    type: TemplateType.Html,
    publicId: `account/templates/date/index.js`,
    secureUrl: `http://path/to/file`,
    thumbnailPublicId: `234`,
    templateUrl: `http://path/to/file`,
    hostedUrl: `http://path/to/file`,
    configValues: {
      formats: [Format.PDF],
      defaultSize: `Full page`,
      sizes: [
        {
          label: `Full page`,
          width: 990,
          height: 1361,
          format: Format.PDF,
          quality: Quality.Optimised,
          unit: Unit.PX,
        },
        {
          label: `Half page`,
          width: 990,
          height: 661,
          format: Format.PDF,
          quality: Quality.Optimised,
          unit: Unit.PX,
        },
      ],
      fields: {
        global: [],
        '001': [
          {
            name: `fieldA`,
            type: `text`,
            inputMode: `text`,
            required: true,
            maxLength: undefined,
            minLength: undefined,
            pattern: undefined,
            options: {
              label: `Field A`,
            },
          },
        ],
      },
      features: [],
    },
    defaultValues: {
      global: {
        language: `en`,
      },
      '001': {
        fieldA: `default value`,
      },
    },
  }

export const mockUserApprover: Approver = {
  id: `123`,
  approverId: `user1`,
  approverType: ApproverType.USER,
  approvalStatus: ApprovalStatus.PENDING,
  updatedAt: ``,
}

export const mockTeamApprover: Approver = {
  id: `123`,
  approverId: `team1`,
  approverType: ApproverType.TEAM,
  approvalStatus: ApprovalStatus.PENDING,
  updatedAt: ``,
}

export const mockApproval: Approval = {
  id: `123`,
  requesterId: `user2`,
  assetId: `asset1`,
  approvers: [mockUserApprover, mockTeamApprover],
  approved: false,
  requesterType: RequesterType.USER,
}

export const mockAnnotation: Annotation = {
  coordinates: {x: 0.5, y: 0.5},
  page: 1,
  version: `123`,
}

export const mockComment: Comment = {
  id: `123`,
  createdAt: `2021-06-18`,
  body: `Test message`,
  userId: `user1`,
  replies: [
    {
      id: `234`,
      createdAt: `2021-06-18`,
      body: `test reply 1`,
      userId: `user2`,
    },
    {id: `345`, createdAt: `2021-06-18`, body: `test reply 2`, userId: `user1`},
  ],
  annotation: mockAnnotation,
}

export const mockCommentCount: CommentCount = {
  entityId: `123`,
  total: 1,
}

export const mockUserAccess: UserAccess = {
  id: `123`,
  name: undefined,
  accessLevel: accessLevels.Owner,
  validFrom: undefined,
  validTo: undefined,
  userId: `user1`,
}

export const mockTeamAccess: TeamAccess = {
  id: `456`,
  name: undefined,
  accessLevel: accessLevels.Viewer,
  validFrom: undefined,
  validTo: undefined,
  teamId: `team1`,
}

export const mockCategories: Category[] = [
  {
    id: `cat1`,
    title: `Category 1`,
    parent: ``,
    active: true,
    createdAt: `2020-01-01 00:00:00`,
  },
  {
    id: `cat2`,
    title: `Category 2`,
    parent: ``,
    active: true,
    createdAt: `2020-01-01 00:00:00`,
  },
  {
    id: `cat3`,
    title: `Category 3`,
    parent: `cat2`,
    active: false,
    createdAt: `2020-01-01 00:00:00`,
  },
]

export const mockSchema: NestedSchema = {
  id: `123`,
  title: `Dealership`,
  name: `dealership`,
  description: `Description of the dealership schema`,
  descendants: [
    {
      id: `234`,
      title: `Dealership Name`,
      name: `dealershipName`,
      type: `string`,
      options: {required: true},
    },
    {
      id: `345`,
      title: `Address`,
      name: `address`,
      type: `string`,
      options: {paragraph: true, required: true},
    },
  ],
  datasetsCount: 0,
  createdAt: `2020-01-01 00:00:00`,
  updatedAt: `2020-01-01 00:00:00`,
  createdById: `user1`,
}
