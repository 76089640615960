import {TriggerProps} from './types'

export const CloudinaryDefaultTrigger = ({
  onClick,
  label = `Upload`,
  ...props
}: TriggerProps) => (
  <button {...props} onClick={onClick}>
    {label}
  </button>
)
