import Pusher, {Channel, Options} from 'pusher-js'
import {MutableRefObject, useEffect, useRef} from 'react'
import useUserSession from '../useUserSession'
import {getPusher} from './helpers'

interface UsePusherInput {
  channelName: string
  eventType: string
  callback: (data?: unknown) => unknown
  init?: boolean
  debug?: boolean
}

interface UsePusherOutput {
  pusherRef: MutableRefObject<Pusher>
  channelRef: MutableRefObject<Channel>
}

export const pusherOptions: Options = {
  cluster: `eu`,
}

const usePusher = ({
  channelName,
  eventType,
  callback,
  init = true,
  debug = false,
}: UsePusherInput): UsePusherOutput => {
  const {cognitoUser, account} = useUserSession()
  const pusherRef = useRef<Pusher>(null)
  const channelRef = useRef<Channel>(null)
  const fullChannelNameRef = useRef<string>(null)

  useEffect(() => {
    return () => {
      channelRef.current?.unbind(eventType)
      pusherRef.current?.unsubscribe(channelName)
    }
  }, [channelName, eventType])

  useEffect(() => {
    Pusher.logToConsole = debug

    if (!init) return

    const fullName = `${account.id}-${cognitoUser.username}-${channelName}`
    if (!pusherRef.current && fullName !== fullChannelNameRef.current) {
      pusherRef.current = getPusher(pusherOptions)
      fullChannelNameRef.current = fullName
      channelRef.current = pusherRef.current.subscribe(
        fullChannelNameRef.current
      )
      channelRef.current.bind(eventType, data => {
        callback(data)
      })
    }
  }, [init, cognitoUser, account, channelName, eventType, callback, debug])

  return {
    channelRef,
    pusherRef,
  }
}

export default usePusher
