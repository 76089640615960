import {useLazyQuery} from '@apollo/client'
import {useCallback} from 'react'
import {AUTHENTICATE_USER, FETCH_USER_DATA} from '../../queries'
import {UseUsersOutput} from '../../types'

export const useUsers = (): UseUsersOutput => {
  const [lazyAuthenticateUser, authenticateUserResult] = useLazyQuery(
    AUTHENTICATE_USER,
    {fetchPolicy: `network-only`}
  )
  const authenticateUser = useCallback(
    (cognitoAccessToken: string, isSesimiAdmin: boolean) =>
      lazyAuthenticateUser({
        variables: {cognitoAccessToken, isSesimiAdmin},
      }),
    [lazyAuthenticateUser]
  )

  const [lazyGetUserData, fetchUserDataResult] = useLazyQuery(FETCH_USER_DATA, {
    fetchPolicy: `cache-and-network`,
    // pollInterval: 60_000, // 1 minute // NOTE: TBD
  })

  const fetchUserData = useCallback(() => lazyGetUserData(), [lazyGetUserData])

  return {
    authenticateUser,
    authenticateUserResult,
    fetchUserData,
    fetchUserDataResult,
  }
}
