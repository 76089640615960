export const paramsToObject = (query: string): {[key: string]: string} => {
  const regex = /^(\?|&)?([\w-]+=[^&]*)?(&[\w-]+=[^&]*)*$/
  const isValid = regex.test(query)
  if (!isValid) return {}
  try {
    const params = new URLSearchParams(query)
    const obj: {[key: string]: string} = {}
    for (const [key, value] of params) {
      obj[key] = value
    }
    return obj
  } catch (error) {
    return {}
  }
}

export const toPascalCase = (s: string) =>
  s
    .toLocaleLowerCase()
    .split(`_`)
    .map(substr => substr.charAt(0).toUpperCase() + substr.slice(1))
    .join(``)

export const toTitleCase = (s: string) =>
  toPascalCase(s).replace(/\w[A-Z]/g, s => s.split(``).join(` `))

export const redirectSource = `meta-auth`
export {redirectSource as metaRedirectSource}

export const metaRedirectUri = (origin: string) => `${origin}/en-US/assets/`

export const createMetaAuthUrl = ({
  scope,
  appId,
  origin,
  apiBaseUrl,
}: {
  scope: string
  appId: string
  origin: string
  apiBaseUrl: string
}) =>
  [
    `https://www.facebook.com/v20.0/dialog/oauth`,
    `?client_id=${appId}`,
    `&redirect_uri=${encodeURIComponent(
      `${apiBaseUrl}/socialIntegrations/metaRedirect`
    )}`,
    `&state=${encodeURIComponent(metaRedirectUri(origin))}`,
    `&scope=${scope}`,
    `&response_type=token`,
  ].join(``)

export const facebookScopes = [
  `pages_show_list`,
  `pages_read_engagement`,
  `pages_manage_posts`,
].join(`,`)

export const instagramScopes = [
  `instagram_basic`,
  `instagram_content_publish`,
  `pages_read_engagement`,
].join(`,`)

export const metaWindowSpecs = () => {
  const width = 600
  const height = 770
  // Automatic centering of Window
  const x = window.outerWidth / 2 + window.screenX - width / 2
  const y = window.outerHeight / 2 + window.screenY - height / 2
  const specs = [
    `toolbar=no, location=no, directories=no, status=no, menubar=no, `,
    `menubar=no, scrollbars=no, resizable=no, copyhistory=no, `,
    `width=${width}, `,
    `height=${height}, `,
    `top=${y}, `,
    `left=${x}`,
  ].join(``)
  return specs
}

export const metaWindowName = `Sesimi Integration Authorization`
