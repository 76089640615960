import {useUsers} from '@myadbox/nebula-service-api'
import {createContext, useEffect} from 'react'

export const UserDataContext = createContext(null)
UserDataContext.displayName = `UserDataContext`

export function UserDataProvider({children}) {
  const {fetchUserData, fetchUserDataResult} = useUsers()

  useEffect(() => {
    fetchUserData()
  }, [fetchUserData])

  // @ts-ignore-next-line called is not in type but available in the response
  if (!fetchUserDataResult.called || fetchUserDataResult.loading) {
    return null
  }

  if (fetchUserDataResult.error) {
    console.error(fetchUserDataResult.error)
    return null
  }

  return (
    <UserDataContext.Provider value={fetchUserDataResult.data?.userData}>
      {children}
    </UserDataContext.Provider>
  )
}
