import {navigate} from 'gatsby'
import {ComponentType} from 'react'
import useUserSession from '../../hooks/useUserSession'

export interface PrivateRouteProps {
  component: ComponentType<unknown>
}

export const PrivateRoute = ({
  component: Component,
  ...rest
}: PrivateRouteProps) => {
  const {isAccountAdmin, isSesimiAdmin} = useUserSession()

  if (!isSesimiAdmin && !isAccountAdmin) {
    navigate(`/dashboard`)
    return
  }

  return <Component {...rest} />
}

export default PrivateRoute
