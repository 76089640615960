import {User} from '@myadbox/nebula-service-api'
import {useLocation} from '@reach/router'
import {useLDClient} from 'launchdarkly-react-client-sdk'
import {createContext, useEffect, useMemo, useState} from 'react'

export const FeatureFlagsContext = createContext(null)
FeatureFlagsContext.displayName = `FeatureFlagsContext`

interface Props {
  children: React.ReactNode | React.ReactNode[]
  currentUser?: User
}

export const FeatureFlagsProvider = ({children, currentUser}: Props) => {
  const [hasFeaturesInit, setHasFeaturesInit] = useState(false)
  const [featureFlags, setFeatureFlags] = useState<Record<string, boolean>>({})
  const ldClient = useLDClient()
  const {hostname} = useLocation()

  useEffect(() => {
    if (!ldClient) return

    const user = currentUser?.userId
      ? {
          key: currentUser.userId,
          email: currentUser.email,
          firstName: currentUser.givenName,
          lastName: currentUser.familyName,
        }
      : {anonymous: true}

    ldClient
      .identify({
        ...user,
        custom: {
          accountName: hostname?.split(`.`)[0],
        },
      })
      .then(flags => {
        setFeatureFlags(flags)
        setHasFeaturesInit(true)
      })
  }, [ldClient, hostname, currentUser])

  const providerValue = useMemo(
    () => ({hasFeaturesInit, featureFlags}),
    [hasFeaturesInit, featureFlags]
  )

  return (
    <FeatureFlagsContext.Provider value={providerValue}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}
