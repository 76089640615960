import {useContext, useDebugValue} from 'react'
import {UserSessionContext} from './UserSessionProvider'
import {UserSession} from './types'

export const useUserSession = () => {
  const userSession = useContext<UserSession>(UserSessionContext)

  if (!userSession) {
    throw new Error(`useUserSession must be used within a UserSessionProvider`)
  }

  useDebugValue(`User session: ${userSession}`)

  return userSession
}

export default useUserSession
