import {
  ReactElement,
  ReactNode,
  cloneElement,
  isValidElement,
  useMemo,
} from 'react'
import UploadWidgetProvider, {UploadWidgetContext} from './UploadWidgetProvider'

import {ResponseHandler, TriggerProps, WidgetOptions} from './types'

import {CloudinaryDefaultTrigger} from './CloudinaryDefaultTrigger'
import {createDefaultProps, handleCloudinaryResponse, noop} from './helpers'

interface Props {
  options?: WidgetOptions
  trigger?: ReactElement<TriggerProps>
  className?: string
  banner?: ReactNode
  successHandler: ResponseHandler
  closeHandler?: ResponseHandler
  createTriggerHandlers?: typeof createDefaultProps
}

const CloudinaryUploadWidget = ({
  options,
  className = ``,
  trigger = <CloudinaryDefaultTrigger />,
  successHandler,
  banner,
  closeHandler = noop,
  createTriggerHandlers = createDefaultProps,
}: Props) => {
  const widgetOptions: Partial<WidgetOptions> = useMemo(
    () => ({
      ...options,
      multiple: options?.multiple ?? true,
      cropping: options?.cropping ?? false,
      resourceType: options?.resourceType ?? `auto`,
    }),
    [options]
  )

  return (
    <div className={className}>
      <UploadWidgetProvider
        onUploaded={handleCloudinaryResponse({
          success: successHandler,
          close: closeHandler,
        })}
        widgetOptions={widgetOptions}
      >
        <UploadWidgetContext.Consumer>
          {(widget): ReactNode => {
            const defaultProps = createTriggerHandlers({
              widget,
              maxFileSize: widgetOptions.maxFileSize,
              resourceType: widgetOptions.resourceType,
            })

            const Trigger = cloneElement(trigger, {
              ...defaultProps,
              ...(isValidElement(trigger) ? trigger.props : {}),
            })
            return Trigger
          }}
        </UploadWidgetContext.Consumer>
      </UploadWidgetProvider>
      {banner}
    </div>
  )
}

CloudinaryUploadWidget.Trigger = CloudinaryDefaultTrigger
CloudinaryUploadWidget.createDefaultProps = createDefaultProps

export default CloudinaryUploadWidget
