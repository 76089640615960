import {isBrowser, setAuthData} from '@myadbox/gatsby-theme-nebula'
import {
  AuthResponse,
  SsoConfiguration,
  SsoIdp,
} from '@myadbox/nebula-service-api'
import {navigate} from 'gatsby'
import {TFunction} from 'i18next'
import qs from 'qs'

const DEFAULT_ACCOUNT_NAME = `SSO LOGIN`
const DEFAULT_LANGUAGE = `en-AU`
export const ADMIN_URL = `/admin` // make sure to match this with the page name

export const parseJson = (input: unknown): NonNullable<unknown> => {
  let item = typeof input !== `string` ? JSON.stringify(input) : input

  try {
    item = JSON.parse(item)
  } catch (e) {
    return {}
  }

  return typeof item === `object` && item !== null ? item : {}
}

export const getSsoAccountName = (names: unknown, language: string) => {
  /**
   * Assumption: `names` is either an object or stringified object
   */
  const nameMap = parseJson(names)
  return nameMap[language] || nameMap[DEFAULT_LANGUAGE] || DEFAULT_ACCOUNT_NAME
}

export const getSsoAccountNames = (idps: SsoIdp[] = [], language: string) => {
  const names = [
    ...new Set(idps.map(idp => getSsoAccountName(idp.name, language))),
  ]
  return names.join(` / `)
}

export const isJson = (inputItem: unknown) => {
  let item =
    typeof inputItem !== `string` ? JSON.stringify(inputItem) : inputItem

  try {
    item = JSON.parse(item)
  } catch (e) {
    return false
  }

  if (typeof item === `object` && item !== null) return true
  return false
}

export const getSsoLoginURL = ({
  idp,
  userPoolAppId,
  ssoConfig,
  redirectUrl,
}: {
  idp: SsoIdp
  userPoolAppId: string
  ssoConfig: SsoConfiguration
  redirectUrl: string
}) => {
  if (!idp || !userPoolAppId || !ssoConfig || !redirectUrl) return `/`

  const scope = ssoConfig?.scope?.split(`,`)?.join(`+`)
  const params = qs.stringify(
    {
      response_type: `token`,
      identity_provider: idp.identifier,
      client_id: userPoolAppId,
      redirect_uri: redirectUrl,
      scope,
    },
    {encode: false}
  )
  return `https://${ssoConfig?.domain}/oauth2/authorize?${params}`
}

export const onAuthenticated = (authResponse: AuthResponse, location): void => {
  setAuthData(authResponse)

  const returnToUrl = parseLocationState(location)?.returnToUrl
  navigate(urlToPathName(returnToUrl) || `/dashboard/`)
}

export const parseLocationState = location => {
  const queryVars = qs.parse(location?.search, {ignoreQueryPrefix: true})
  return Object.keys(queryVars).length === 0 ? location?.state : queryVars
}

// Forces relative path which stops highjacking of the login return URL to another website
export const urlToPathName = (returnToUrl: string): string | null => {
  if (!returnToUrl) return
  try {
    const url = new URL(returnToUrl)
    return `${url.pathname}${url.search}`
  } catch {
    return null
  }
}

export const handleError = (error: string, show: boolean): string => {
  // TODO: validate that this replacement is still valid
  // Sesimi does not use email address as username
  return show && error && typeof error === `string`
    ? error.replace(/username/i, `email`)
    : null
}

export const logError = (err: string): void => {
  if (isBrowser() && window[`Sentry`]) {
    // eslint-disable-next-line no-console
    console.debug(err)
    window[`Sentry`].captureMessage(err)
  }
}

export const handleForgotPwdErrors = (error, t: TFunction): string => {
  switch (error.code) {
    case `NotAuthorizedException`:
      return t`login.forgot.errors.notAuthorised`

    case `UserNotFoundException`:
      return t`login.forgot.errors.notFound`

    case `LimitExceededException`:
      return error.message

    default:
      return ``
  }
}
