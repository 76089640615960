import {User} from '@myadbox/nebula-service-api'
import {createContext, useMemo} from 'react'
import {returnToLogin} from '../../utils/returnToLogin'
import {clearAuthData} from '../../utils/session'
import {FeatureFlagsProvider} from '../useFeatureFlags/FeatureFlagsProvider'
import {useLocalStorage} from '../useLocalStorage/useLocalStorage'
import {UserProvider} from '../useProfile/UserProvider'
import {UserDataProvider} from '../useUserData'
import {asIs, hasAuthTokenExpired, parseToken} from './helpers'
import {CognitoAuthToken, JwtToken, UserSession} from './types'

export const UserSessionContext = createContext(null)
UserSessionContext.displayName = `UserSessionContext`

export const UserSessionProvider = ({children}) => {
  const [cognitoAuthToken] = useLocalStorage<CognitoAuthToken>(
    `cognito-session`,
    null
  )
  const [jwt] = useLocalStorage<string>(`nf_jwt`, null, {
    serialize: asIs,
    deserialize: asIs,
  })

  const jwtPayload = useMemo(() => parseToken<JwtToken>(jwt), [jwt])
  const isValidSession = !hasAuthTokenExpired(jwtPayload)

  const userSession: UserSession = useMemo(
    () => ({
      jwt,
      cognitoAuthToken,
      cognitoUser: jwtPayload?.cognitoUser,
      account: jwtPayload?.account,
      isAccountAdmin: jwtPayload?.isAccountAdmin,
      isSesimiAdmin: jwtPayload?.isSesimiAdmin,
    }),
    [jwt, jwtPayload, cognitoAuthToken]
  )

  if (!cognitoAuthToken || !jwt || !isValidSession) {
    clearAuthData()
    returnToLogin()

    return
  }

  return (
    <UserSessionContext.Provider value={userSession}>
      <UserDataProvider>
        <UserProvider>
          {(currentUser: User) => (
            <FeatureFlagsProvider currentUser={currentUser}>
              {children}
            </FeatureFlagsProvider>
          )}
        </UserProvider>
      </UserDataProvider>
    </UserSessionContext.Provider>
  )
}

export default UserSessionProvider
