import {createSrcSet, injectTransforms} from '@myadbox/stellar-ui'
import {sanitizeCsvString} from './src/utils/csv'
import {
  localeMap,
  shortLocaleFormats,
  supportedLocaleFormats,
} from './src/utils/dates'
import {
  downloadErrorTextFile,
  getToggledClassName,
  isBrowser,
  isEmptyObject,
  slugifyText,
  startsWithSpecialChar,
  stripTrailingFileName,
  stripTrailingSlash,
  toTitleCase,
} from './src/utils/helpers'
import {getRenderTranslated} from './src/utils/i18n/getRenderTranslated'
import {noop} from './src/utils/noop'
import {clearAuthData, isValidSession, setAuthData} from './src/utils/session'
import silhouetteStylesWhen from './src/utils/states/silhouetteStylesWhen'
import {
  dateStyles,
  getDefaultTimezone,
  getUserTimezoneTime,
  numDateStyles,
  timeStyles,
  toLocaleTimezone,
  toUTC,
} from './src/utils/timezone'

export {
  clearAuthData,
  createSrcSet,
  downloadErrorTextFile,
  getDefaultTimezone,
  getRenderTranslated,
  getToggledClassName,
  getUserTimezoneTime,
  injectTransforms,
  isBrowser,
  isEmptyObject,
  isValidSession,
  localeMap,
  noop,
  sanitizeCsvString,
  setAuthData,
  shortLocaleFormats,
  silhouetteStylesWhen,
  slugifyText,
  startsWithSpecialChar,
  stripTrailingFileName,
  stripTrailingSlash,
  supportedLocaleFormats,
  toLocaleTimezone,
  toTitleCase,
  toUTC,
}

export default {
  clearAuthData,
  createSrcSet,
  dateStyles,
  downloadErrorTextFile,
  getDefaultTimezone,
  getToggledClassName,
  getUserTimezoneTime,
  isValidSession,
  injectTransforms,
  isBrowser,
  isEmptyObject,
  localeMap,
  noop,
  numDateStyles,
  sanitizeCsvString,
  setAuthData,
  shortLocaleFormats,
  silhouetteStylesWhen,
  slugifyText,
  stripTrailingFileName,
  stripTrailingSlash,
  supportedLocaleFormats,
  timeStyles,
  toLocaleTimezone,
  toTitleCase,
  toUTC,
}
