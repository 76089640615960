import mixpanel from 'mixpanel-browser'
import {getCurrentUserData} from './mixpanelSetup'
import {TrackedEvent, trackedEvents} from './trackedEvents'

export const trackEventByKey = (
  eventKey: TrackedEvent,
  additionalProperties: Record<string, unknown> = {}
) => {
  if (!eventKey) return
  const event = trackedEvents[eventKey]
  if (event) {
    const {name, properties} = event
    const {userId, companyName, mixpanelTokenSet} = getCurrentUserData()
    if (mixpanelTokenSet && userId) {
      mixpanel.track(name, {
        properties,
        ...additionalProperties,
        distinct_id: userId,
        Company: companyName,
      })
    }
  } else {
    console.error(`Event "${eventKey}" is not defined in trackedEvents.`)
  }
}
