import {
  type TextAreaProps,
  TextArea as StellarTextArea,
  TextAreaHelpers,
} from '@myadbox/stellar-ui'
import TextAreaFormik from './TextAreaFormik'

export type {TextAreaProps} from '@myadbox/stellar-ui'

export const {
  textAreaClassName,
  sizesClassName,
  getTextAreaClassName,
  enabledClassName,
  disabledClassName,
} = TextAreaHelpers ?? {}

const TextArea = ({noResize = true, size = `md`, ...props}: TextAreaProps) => {
  return <StellarTextArea noResize={noResize} size={size} {...props} />
}

TextArea.Formik = TextAreaFormik
export default TextArea
