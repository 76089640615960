// code was originally ripped off https://github.com/toriihq/gatsby-plugin-intercom-spa/blob/master/src/gatsby-browser.js
// but amended to login and logout users
import jwtDecode from 'jwt-decode'

//safety check
const isEnabled = () =>
  (window.IntercomIncludeInDevelopment ||
    process.env.NODE_ENV === `production`) &&
  typeof Intercom === `function` &&
  window.IntercomAppId

// Revisit identity verification
// const getUserHash = email => {
//   const hmac = CryptoJS.algo.HMAC.create(
//     CryptoJS.algo.SHA256,
//     process.env.GATSBY_INTERCOM_SECRET
//   ).update(email)

//   return CryptoJS.enc.Hex.stringify(hmac.finalize())
// }

const getUser = () => {
  //code ripped from some answer on: https://stackoverflow.com/questions/10730362/get-cookie-by-name
  const authToken = window.localStorage.getItem(`nf_jwt`)
  if (!authToken) return

  try {
    const {cognitoUser} = jwtDecode(authToken)
    return cognitoUser
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(`Failed to parse authToken`, err)
  }
}

export const onInitialClientRender = () => {
  if (!isEnabled()) return

  const company = {
    company_id: location.hostname?.split(`.`)[0],
    name: location.hostname?.split(`.`)[0],
  }

  const user = getUser()
  if (user && process.env.GATSBY_INTERCOM_SECRET) {
    const {attributes, username} = user
    // Revisit identity verification
    // const user_hash = getUserHash(attributes?.email)
    window.Intercom(`boot`, {
      app_id: window.IntercomAppId,
      email: attributes?.email,
      name: `${attributes?.given_name}  ${attributes?.family_name}`,
      // This needs to remove when identity verification is enabled
      user_id: username,
      account: location.hostname?.split(`.`)[0],
      // user_hash,
      company,
    })
  } else {
    window.Intercom(`boot`, {
      app_id: window.IntercomAppId,
      account: company.company_id,
    })
  }
}

export const onRouteUpdate = function ({location, prevLocation}) {
  if (!isEnabled()) {
    return
  }
  const company = {
    company_id: location.hostname?.split(`.`)[0],
    name: location.hostname?.split(`.`)[0],
  }

  //logout user
  if (location.pathname === `/logout/` || location.pathname === `/`) {
    //reset intercom to clean state after user logs out
    window.Intercom(`shutdown`)
    window.Intercom(`boot`, {
      app_id: window.IntercomAppId,
      account: company.company_id,
    })
  }
  // login user
  if (
    location.pathname.includes(`/dashboard/`) &&
    (prevLocation?.pathname.includes(`/admin`) ||
      prevLocation?.pathname === `/` ||
      prevLocation?.pathname.slice(6) === `/`) // Remove language from url
  ) {
    const user = getUser()
    if (user) {
      const {attributes, username} = user
      // Revisit identity verification
      // const user_hash = getUserHash(attributes?.email)

      window.Intercom(`update`, {
        email: attributes?.email,
        name: `${attributes?.given_name} ${attributes?.family_name}`,
        company,
        account: company.company_id,
        // This needs to remove when identity verification is enabled
        user_id: username,
        // user_hash,
      })
    }
  }
  // update intercom on route change with what ever data is already stored
  // maybe this causes a double call on login/logout dunno, seems to work
  window.Intercom(`update`)
}
