import {User, useProfiles} from '@myadbox/nebula-service-api'
import {createContext, useMemo} from 'react'
import {getProfileWithTZ} from './helpers'

export const UserContext = createContext<User | undefined>(undefined)
UserContext.displayName = `UserContext`

interface Props {
  children: (currentUser: User) => React.ReactNode | React.ReactNode[]
}

export const UserProvider = ({children}: Props) => {
  const {profile} = useProfiles()
  const userProfile = useMemo(() => getProfileWithTZ(profile), [profile])

  return (
    <UserContext.Provider value={userProfile}>
      {children(profile)}
    </UserContext.Provider>
  )
}
