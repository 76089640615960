import {ButtonHTMLAttributes} from 'react'
import StaticIcon from '../StaticIcon'
import Button from '../Button'
import Dropdown from '../Dropdown'

const noop = () => null

export interface TriggerProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onSelect?: (e: React.MouseEvent<HTMLButtonElement>) => void
  uploadText?: string
  rawFilesText?: string
}

const DefaultUploadTrigger = ({
  onClick = noop,
  uploadText = `Upload`,
  ...props
}: TriggerProps) => (
  <Button
    {...props}
    variant="primary"
    onClick={onClick}
    iconSuffix={<StaticIcon name="upload" />}
    aria-label={uploadText}
  >
    {uploadText}
  </Button>
)

const RawUploadTrigger = ({
  onClick = noop,
  onSelect = noop,
  uploadText = `Upload`,
  rawFilesText = `Upload raw files`,
  ...props
}: TriggerProps) => {
  return (
    <Dropdown
      trigger={
        <Dropdown.Button
          {...props}
          id="builder-download-menu-trigger"
          onButtonClick={onClick}
          ariaLabel={uploadText}
        >
          {uploadText}
        </Dropdown.Button>
      }
    >
      <Dropdown.Item onSelect={onSelect}>{rawFilesText}</Dropdown.Item>
    </Dropdown>
  )
}

const CloudinaryUploaderTriggers = {
  Default: DefaultUploadTrigger,
  Raw: RawUploadTrigger,
}

export default CloudinaryUploaderTriggers
