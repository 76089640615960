import {
  CloudinaryResponders,
  CloudinaryUploadWidgetProps,
  ResourceType,
  UploadResponse,
  WidgetOptions,
} from './types'

export const uploadTypes = {
  Asset: `asset`,
  Csv: `csv`,
} as const

export const widgetSizeConfig: {
  default: Partial<WidgetOptions>
  large: Partial<WidgetOptions>
} = {
  default: {
    maxImageFileSize: 314572800,
    maxVideoFileSize: 3146063544,
    maxFileSize: 5368709120,
    resourceType: `auto`,
  },
  large: {
    maxImageFileSize: 5368709120,
    maxVideoFileSize: 5368709120,
    maxFileSize: 5368709120,
    resourceType: `raw`,
  },
}

export const handleCloudinaryResponse = (callbacks: CloudinaryResponders) => {
  return (error: string, response: UploadResponse): void => {
    if (response.event in callbacks !== true) return

    if (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      return
    }

    callbacks[response.event](response)
  }
}

type PropCreatorInput = {
  widget: CloudinaryUploadWidgetProps | null
  maxFileSize?: number
  resourceType?: ResourceType
}
type ExposedTriggerProp = `onClick` | `onSelect`

export const createDefaultProps = ({
  widget,
  maxFileSize,
  resourceType = `auto`,
}: PropCreatorInput): Partial<Record<ExposedTriggerProp, () => void>> => ({
  onClick: () => {
    let widgetConfig = widgetSizeConfig.default

    if (resourceType === `raw`) widgetConfig = widgetSizeConfig.large
    if (maxFileSize) widgetConfig = {...widgetConfig, maxFileSize}

    widget?.update?.(widgetConfig)
    widget?.open(`local`)
  },
  onSelect: () => {
    widget?.update?.(widgetSizeConfig.large)
    widget?.open(`local`)
  },
})

export const noop = () => null
