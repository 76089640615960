import {Button} from '@myadbox/gatsby-theme-nebula'
import {useAccount} from '@myadbox/nebula-service-api'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {CloudinaryUploader, UploadResponse} from '../helpers'
import {WidgetOptions} from '@myadbox/cloudinary-upload-widget'

const cloudinaryUploaderOptions: Partial<WidgetOptions> = {
  cropping: true,
  multiple: false,
  resourceType: `image`,
  clientAllowedFormats: [`png`, `jpg`, `jpeg`, `webp`, `svg`],
  minImageHeight: 50,
  minImageWidth: 50,
  tags: [`login-logo`],
  sources: [`local`, `url`],
}

const AccountLogoUpload = () => {
  const {t} = useTranslation()
  const {updateAccountImageConfig, updateAccountImageConfigResponse} =
    useAccount()

  const successHandler = (
    data: UploadResponse,
    accImageType: `background` | `logo`
  ) => {
    const configKey =
      accImageType === `background` ? `loginBackgroundUrl` : `logoUrl`
    updateAccountImageConfig({[configKey]: data.info.secure_url})
  }

  return (
    <CloudinaryUploader
      options={cloudinaryUploaderOptions}
      trigger={
        <Button
          aria-label={t`settings.account.configurations.button.uploadLogoAriaLabel`}
          size="sm"
          variant="secondary"
          type="button"
          loading={updateAccountImageConfigResponse?.loading}
          id="uploadLogo"
        >
          {t`settings.upload`}
        </Button>
      }
      successHandler={data => successHandler(data, `logo`)}
    />
  )
}

export default AccountLogoUpload
