import {navigate} from 'gatsby'
import {isBrowser} from './helpers'
import {isArkaroolaPage} from './page'

export const returnToLogin = (): void => {
  if (!isBrowser()) return
  if (isArkaroolaPage(window.location.pathname)) return

  // check if user is sesimi admin
  const isAdmin = localStorage.getItem(`isAdmin`) === `true`

  // To replace language from path in returnToUrl so that navation with language works
  const language = localStorage.getItem(`gatsby-i18next-language`)
  const urlWithoutLang = window.location.href.replace(`/${language}/`, `/`)

  // Avoid adding another returnToUrl if existing
  const url = new URL(urlWithoutLang)
  const returnToUrl = url.searchParams.has(`returnToUrl`)
    ? ``
    : `?returnToUrl=${urlWithoutLang}`

  const navigateUrl = `${isAdmin ? `/admin/` : `/`}${returnToUrl}`

  navigate(navigateUrl)
}
