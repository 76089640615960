export const EVENTS = {
  DOWNLOAD_TEMPLATE: `DOWNLOAD_TEMPLATE`,
  SAVE_TO_ASSETS: `SAVE_TO_ASSETS`,
  DONE_BUTTON: `DONE_BUTTON`,
} as const

export type TrackedEvent = (typeof EVENTS)[keyof typeof EVENTS]

type TrackedEventProperties = {name: string; properties: {section: string}}

export const trackedEvents = {
  DOWNLOAD_TEMPLATE: {
    name: `TEMPLATE_DOWNLOAD_ASSET`,
    properties: {section: `Templates`},
  },
  SAVE_TO_ASSETS: {
    name: `TEMPLATE_SAVE_ASSET`,
    properties: {section: `Assets`},
  },
  DONE_BUTTON: {name: `ASSET_UPLOAD_DONE`, properties: {section: `Upload`}},
} as const satisfies Record<TrackedEvent, TrackedEventProperties>
