import {supportedLanguages} from '@myadbox/nebula-languages'

const NONAUTH_PAGES = [`forgot`, `reset`, `logout`, `admin`]

export const isArkaroolaPage = (pathname: string): boolean => {
  const page = pathname.replace(/\/$/, ``).split(`/`).pop()
  const oneOfThePages = NONAUTH_PAGES.includes(page)
  const isBaseUrl =
    pathname === `/` || Object.keys(supportedLanguages).includes(page)
  return oneOfThePages || isBaseUrl
}

export const isExternalPage = (pathname: string): boolean => {
  return Boolean(new RegExp(/\/external\/.+(\/)?$/).exec(pathname))
}

export const isPublicPage = (pathname: string): boolean => {
  return isArkaroolaPage(pathname) || isExternalPage(pathname)
}
