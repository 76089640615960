import {ApolloClient, ApolloLink} from '@apollo/client'
import {BatchHttpLink} from '@apollo/client/link/batch-http'
import {setContext} from '@apollo/client/link/context'
import {onError} from '@apollo/client/link/error'
import {MultiAPILink} from '@habx/apollo-multi-endpoint-link'
import fetch from 'cross-fetch'

import {cache} from './cache'

const serviceNames = [
  `access`,
  `access/external`,
  `accounts`,
  `approvals`,
  `assets`,
  `comments`,
  `datasets`,
  `profiles`,
  `report`,
  `templates`,
  `users`,
  `gateway`,
  `billing`,
  `brands`,
  `integrations`,
  `socialIntegrations`,
]

const getAuthToken = (): string => {
  return localStorage.getItem(`nf_jwt`)
}

const endpoints = serviceNames.reduce((acc, name) => {
  acc[name] = `${process.env.GATSBY_SERVICES_API_GATEWAY}/${name}`
  return acc
}, {})

const authLink = setContext((_, {headers}) => {
  const token = getAuthToken()
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ``,
    },
  }
})

const createErrorLink = (errorCallback: () => void) => {
  return onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({message, extensions}) => {
        // eslint-disable-next-line no-console
        console.error(`[GraphQL error]: Message: ${message}`)
        if (extensions.code === `UNAUTHENTICATED`) {
          errorCallback()
        }
      })
    // eslint-disable-next-line no-console
    if (networkError) console.error(`[Network error]: ${networkError}`)
  })
}

export const getClient = (options = {errorCallback: () => null}) =>
  new ApolloClient({
    link: ApolloLink.from([
      authLink,
      createErrorLink(options.errorCallback),
      // @ts-ignore "unknown error"
      new MultiAPILink({
        httpSuffix: ``,
        endpoints,
        createHttpLink: () => {
          // Allow batching requests
          return new BatchHttpLink({fetch})
        },
      }),
    ]),
    cache,
    connectToDevTools: process.env.GATSBY_ENABLE_APOLLO_DEV_TOOLS === `true`,
    defaultOptions: {
      mutate: {
        errorPolicy: `all`,
      },
    },
  })
