import {gql} from '@apollo/client'

export const AUTHENTICATE_USER = gql`
  query Authenticate($cognitoAccessToken: String!, $isSesimiAdmin: Boolean!)
  @api(name: "users") {
    authenticate(
      cognitoAccessToken: $cognitoAccessToken
      isSesimiAdmin: $isSesimiAdmin
    ) {
      token
    }
  }
`

export const FETCH_USER_DATA = gql`
  query GetUserData @api(name: "users") {
    userData {
      teamIds
      accesses {
        id
        shortId
        accessLevel
      }
      searchKey
    }
  }
`
