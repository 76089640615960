import {gql, useLazyQuery} from '@apollo/client'
import {isValidSession} from '@myadbox/gatsby-theme-nebula'
import {initUserSession} from '@myadbox/mixpanel'
import {useCallback, useEffect, useState} from 'react'

const FETCH_CURRENT_USER_PROFILE = gql`
  query ProfileByCurrentUser @api(name: "profiles") {
    profileByCurrentUser {
      userId
    }
  }
`

const App = ({children}) => {
  const [session, setSession] = useState(null)

  const [getProfileId, {data}] = useLazyQuery(FETCH_CURRENT_USER_PROFILE)

  const handleStorageEvent = useCallback(
    event => {
      if (event.storageArea === localStorage) {
        const token = localStorage.getItem('nf_jwt')
        if (token && isValidSession(token)) {
          setSession(prev => (prev !== token ? token : prev))
          getProfileId()
        } else {
          setSession(null)
        }
      }
    },
    [getProfileId]
  )

  useEffect(() => {
    window.addEventListener('storage', handleStorageEvent)
    return () => window.removeEventListener('storage', handleStorageEvent)
  }, [handleStorageEvent])

  useEffect(() => {
    if (isValidSession(session)) {
      getProfileId()
    }
  }, [session, getProfileId])

  useEffect(() => {
    if (data?.profileByCurrentUser?.userId) {
      initUserSession(data.profileByCurrentUser.userId)
    }
  }, [data])

  return children
}

export const wrapRootElement = ({element}) => <App>{element}</App>
