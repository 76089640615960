import jwtDecode from 'jwt-decode'
import {JwtToken} from './types'

export const hasAuthTokenExpired = (jwtPayload: JwtToken): boolean => {
  if (!jwtPayload) return true

  return jwtPayload.exp < Date.now() / 1_000 // Convert milliseconds to seconds
}

export const parseToken = <T>(token: string): T | null => {
  if (!token) return null

  try {
    return jwtDecode<T>(token)
  } catch (e) {
    return null
  }
}

export const asIs = <T>(value: T): T => value
