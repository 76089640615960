import {Modal} from '@myadbox/gatsby-theme-nebula'
import {
  ConnectToGoogleInput,
  useGoogleConnect,
  useIntegrations,
} from '@myadbox/nebula-service-api'
import {ActionGroup, Button, GoogleSheetsIcon, Text} from '@myadbox/stellar-ui'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {redirectSource} from '../helpers'
import {useClientGoogleAuth} from '../hooks/useClientGoogleAuth'
import useGoogleModalStore from '../hooks/useGoogleSheetsModalStore'

const appId = process.env.GATSBY_GOOGLE_CLIENT_ID || ``
const apiBaseUrl = process.env.GATSBY_SERVICES_API_GATEWAY || ``

export const handleGoogleReturnMessageBuilder = ({
  connectToGoogle,
  integrationId,
}: {
  connectToGoogle: (connectToGoogleInput: ConnectToGoogleInput) => void
  integrationId: string
}) => {
  return async (event: {
    origin: string
    data: {payload: {code: string; scope: string}; source: string}
  }) => {
    const {payload, source} = event.data
    if (source === redirectSource && payload) {
      connectToGoogle({integrationId, authCode: payload.code})
    }
  }
}

const DefaultModal = () => {
  const {t} = useTranslation(`googleSheetIntegrations`)
  const {isModalOpen, setModalOpen, integrationId, setModalState} =
    useGoogleModalStore(state => state)
  const {
    connectToGoogle,
    googleConnectResponse: {loading, error, data, called},
  } = useGoogleConnect()
  const {
    fetchIntegrationsResults: {refetch},
  } = useIntegrations()
  const handleReturnMessage = handleGoogleReturnMessageBuilder({
    connectToGoogle,
    integrationId,
  })
  const {handleGoogleLogin} = useClientGoogleAuth(handleReturnMessage, appId)
  const handleCancel = () => setModalOpen()
  const handleConnect = () => {
    handleGoogleLogin(`GoogleSheet`, apiBaseUrl)
  }

  useEffect(() => {
    // apollo error
    if (error) {
      setModalState(`error`)
    }

    if (called && !loading && data?.connectToGoogle?.success === `true`) {
      refetch()
      setModalOpen()
      window.location.reload()
    } else if (
      called &&
      !loading &&
      data?.connectToGoogle?.success === `false`
    ) {
      setModalState(`error`)
    }
  }, [error, loading, data, called, refetch, setModalOpen, setModalState])
  return (
    <Modal
      controls={{isOpen: isModalOpen, onClose: setModalOpen}}
      maxWidth="md"
      id="default-integrations-modal"
    >
      <Modal.Header>{t(`defaultModal.title`)}</Modal.Header>
      <Modal.Body>
        <div className="flex flex-col gap-4">
          <GoogleSheetsIcon size={34} />
          <Text>{t(`defaultModal.description`)}</Text>
          <ActionGroup>
            <Button variant="secondary" onClick={handleCancel}>
              {t(`button.cancel`)}
            </Button>
            <Button variant="primary" onClick={handleConnect}>
              {t(`button.connect`)}
            </Button>
          </ActionGroup>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DefaultModal
